import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-un-authorize',
    templateUrl: './un-authorize.component.html',
    styleUrls: ['./un-authorize.component.css']
})
export class UnAuthorizeComponent implements OnInit {
    constructor(private route: Router) { }
    ngOnInit() {
    };
    Login() {
        this.route.navigate(['/login']);
    };
}