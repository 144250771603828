import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertService } from '../../common/alert.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    constructor(private notificationsService: AlertService, private messageService: MessageService) { }
    ngOnInit() {
        this.subscribeToNotifications();
    }

    subscribeToNotifications() {
        this.subscription = this.notificationsService.notificationChange
            .subscribe((message: any) => {
                this.messageService.add({ severity: message.severity, summary: message.summary, detail: message.detail });
            });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}