import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTokenHandler } from '../../common/app-token-handler';

@Component({
    selector: 'app-session-expired',
    templateUrl: './session-expired.component.html',
    styleUrls: ['./session-expired.component.css']
})
export class SessionExpiredComponent implements OnInit {
    constructor(private appToken: AppTokenHandler, private route: Router) { }
    ngOnInit() {
        this.appToken.clearSessionToken();
    };
    Login() {
        this.route.navigate(['/login']);
    };
}