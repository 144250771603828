import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { DataService } from '../../common/data.service';
import { AppTokenHandler } from '../../common/app-token-handler';
import { ICourierRequestDetailUI, IRolesAndActions, ICourierCompany, ISubLocation, ILocation, IFloor, IEmpDetails, IAuditTrail } from '../../model/courier-request-detail';
import { AlertService } from '../../common/alert.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { CustomValidators } from '../../common/Validators/CustomeValidators';
import { MessageService } from 'primeng/api';
import { debounceTime } from 'rxjs/operators';

enum Status {
    New = 0,
    Submitted = 1,
    SentForDelivery = 2,
    Delivered = 3,
    Discarded = 4,
    Updated = 5
}
@Component({
    selector: 'app-create-new-request',
    templateUrl: './create-new-request.component.html',
    styleUrls: ['./create-new-request.component.css']
})
export class CreateNewRequestComponent implements OnInit {
    LocationOwnerRequired: boolean = false;
    confirmtype: string = "";
    blockSpecial: RegExp = /[^@\\#$%^*()?':{}|<>_+=~!]/;
    ViewAuditTrail: boolean = false;
    OtherCourierCompanyRequired: boolean = false;
    private RequestID: string = "0";
    private CourierRequestDetailUI: ICourierRequestDetailUI = null;
    RolesAndActions: IRolesAndActions = null;
    private CourierCompanyList: Array<ICourierCompany> = [];
    private SenderOfficeList: Array<ISubLocation> = [];
    private LocationList: Array<ILocation> = [];
    private SubLocationList: Array<ISubLocation> = [];
    private FloorList: Array<IFloor> = [];
    private EmpDetails: Array<IEmpDetails> = [];
    private AuditTrail: Array<IAuditTrail> = [];
    private SelectedCourierCompany: ICourierCompany = undefined;
    private SelectedSenderOffice: ISubLocation = null;
    private SelectedLocation: ILocation = null;
    private SelectedSubLocation: ISubLocation = null;
    private SelectedFloor: IFloor = null;
    private SelectedEmp: IEmpDetails = null;
    private IsSubAWBRequired: boolean = true;
    private IsReceivedLocationRequired: boolean = true;
    courierForm: UntypedFormGroup;
    auditTrailColumns: any = [];
    constructor(private router: Router, private route: ActivatedRoute,
        private gservice: DataService, private fb: UntypedFormBuilder,
        private alertService: AlertService,
        private messageService: MessageService,
        private appToken: AppTokenHandler) {
    }
    ngOnInit() {
        let ID: string = this.route.snapshot.paramMap.get('id');
        if (ID) {
            this.RequestID = this.gservice.DecryptData(ID);
        }
        this.InitializeValues();
    }
    ResetValues() {
        this.LocationOwnerRequired = false;
        this.confirmtype = "";
        this.ViewAuditTrail = false;
        this.OtherCourierCompanyRequired = false;
        this.CourierRequestDetailUI = null;
        this.RolesAndActions = null;
        this.CourierCompanyList = [];
        this.SenderOfficeList = [];
        this.LocationList = [];
        this.SubLocationList = [];
        this.FloorList = [];
        this.EmpDetails = [];
        this.AuditTrail = [];
        this.SelectedCourierCompany = undefined;
        this.SelectedSenderOffice = null;
        this.SelectedLocation = null;
        this.SelectedSubLocation = null;
        this.SelectedFloor = null;
        this.SelectedEmp = null;
        this.IsSubAWBRequired = true;
        this.IsReceivedLocationRequired = true;
        this.courierForm = null;
        this.auditTrailColumns = [];
    }
    InitializeValues() {
        this.ResetValues();
        this.auditTrailColumns = [
            { field: 'RecordID', header: 'Record ID' },
            { field: 'Action', header: 'Action' },
            { field: 'DisplayName', header: 'Field Name' },
            { field: 'OldValue', header: 'Old Value' },
            { field: 'NewValue', header: 'New Value' },
            { field: 'CreatedBy', header: 'Created By' },
            { field: 'CreatedOn', header: 'Created On' }
        ];
        this.RolesAndActions = {
            CanView: false,
            CanSubmit: false,
            CanUpdate: false,
            CanDiscard: false,
            CanSendForDelivery: false,
            CanMarkDelivered: false,
            CanViewCourierMailRoomProcess: false,
            CanViewReceiverInformation: false,
            CanEditCourierDetails: false,
            CanEditCourierMailRoomProcess: false,
            CanEditReceiverInformation: false,
        }
        this.getRequestDetails();
    }
    getRequestDetails() {
        this.gservice.GetData("CourierRequestDetails/" + this.RequestID).subscribe((data: any) => {
            this.CourierRequestDetailUI = data;
            this.RolesAndActions = this.CourierRequestDetailUI.RolesAndActions;
            this.CourierCompanyList = this.CourierRequestDetailUI.CourierCompanyList;
            this.SenderOfficeList = this.CourierRequestDetailUI.SenderOfficeList;
            this.LocationList = this.CourierRequestDetailUI.LocationList;
            this.SubLocationList = this.CourierRequestDetailUI.SubLocationList;
            this.FloorList = this.CourierRequestDetailUI.FloorList;
            if (this.CourierRequestDetailUI.CourierRequestDetails.RequestID > 0) {
                this.AuditTrail = this.CourierRequestDetailUI.AuditTrail;
            }
            this.SelectedCourierCompany = {
                CourierCompanyID: this.CourierRequestDetailUI.CourierRequestDetails.CourierCompanyID,
                CourierCompanyName: this.CourierRequestDetailUI.CourierRequestDetails.CourierCompanyID === 1 ? "Other" : this.CourierRequestDetailUI.CourierRequestDetails.CourierCompanyName
            }
            this.SelectedSenderOffice = {
                SubLocationID: this.CourierRequestDetailUI.CourierRequestDetails.ReceivedFromOfficeID, SubLocationName: this.CourierRequestDetailUI.CourierRequestDetails.ReceivedFromOfficeName
            }
            this.SelectedLocation = {
                LocationID: this.CourierRequestDetailUI.CourierRequestDetails.LocationID, LocationName: this.CourierRequestDetailUI.CourierRequestDetails.LocationName
            }
            this.SelectedSubLocation = {
                SubLocationID: this.CourierRequestDetailUI.CourierRequestDetails.SubLocationID, SubLocationName: this.CourierRequestDetailUI.CourierRequestDetails.SubLocationName
            }
            this.SelectedFloor = {
                FloorID: this.CourierRequestDetailUI.CourierRequestDetails.FloorNumber, FloorNumber: this.CourierRequestDetailUI.CourierRequestDetails.FloorNumber
            }
            if (this.CourierRequestDetailUI.RolesAndActions.CanSendForDelivery) {
                this.CourierRequestDetailUI.CourierRequestDetails.HandedOverDate = new Date();
            }

            if (this.CourierRequestDetailUI.CourierRequestDetails.RequestID > 0) {
                this.SelectedEmp = {
                    EmpLPN: this.CourierRequestDetailUI.CourierRequestDetails.EmpLPN,
                    EmpGPN: this.CourierRequestDetailUI.CourierRequestDetails.EmpGPN,
                    EmpName: this.CourierRequestDetailUI.CourierRequestDetails.EmpName,
                    EmpNameOnly: this.CourierRequestDetailUI.CourierRequestDetails.EmpName,
                    EmpEmail: this.CourierRequestDetailUI.CourierRequestDetails.EmpEmail,
                    EmpMobile: this.CourierRequestDetailUI.CourierRequestDetails.EmpMobile,
                    EmpOffPhone: this.CourierRequestDetailUI.CourierRequestDetails.EmpOffPhone,
                    EmpOU: this.CourierRequestDetailUI.CourierRequestDetails.EmpOU,
                    EmpOUDescr: this.CourierRequestDetailUI.CourierRequestDetails.EmpOUDescr,
                    EmpBU: this.CourierRequestDetailUI.CourierRequestDetails.EmpBU,
                    EmpBUDescr: this.CourierRequestDetailUI.CourierRequestDetails.EmpBUDescr,
                    EmpFirmID: this.CourierRequestDetailUI.CourierRequestDetails.EmpFirmID,
                    EmpFirm: this.CourierRequestDetailUI.CourierRequestDetails.EmpFirm,
                    EmpOffLocationID: this.CourierRequestDetailUI.CourierRequestDetails.EmpOffLocationID,
                    EmpOffDescription: this.CourierRequestDetailUI.CourierRequestDetails.EmpOffDescription
                }
            }
            this.courierForm = this.fb.group({
                RequestID: [this.CourierRequestDetailUI.CourierRequestDetails.RequestID, []],
                ReceivedDate: [{ value: new Date(this.CourierRequestDetailUI.CourierRequestDetails.ReceivedDate), disabled: true }, []],
                PacketReceivedType: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.PacketReceivedType, disabled: !this.RolesAndActions.CanEditCourierDetails }, []],
                PacketType: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.PacketType, disabled: !this.RolesAndActions.CanEditCourierDetails }, []],
                AWBNumber: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.AWBNumber, disabled: !this.RolesAndActions.CanEditCourierDetails },
                [Validators.required, Validators.maxLength(15), CustomValidators.SpecialCharacterCheck]],
                SubAWBNumber: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.SubAWBNumber, disabled: !this.RolesAndActions.CanEditCourierDetails }, []],
                ReceivedFromOfficeID: [this.CourierRequestDetailUI.CourierRequestDetails.ReceivedFromOfficeID, []],
                ReceivedFromOfficeName: [this.CourierRequestDetailUI.CourierRequestDetails.ReceivedFromOfficeName, []],
                LocationID: [this.CourierRequestDetailUI.CourierRequestDetails.LocationID, []],
                LocationName: [this.CourierRequestDetailUI.CourierRequestDetails.LocationName, []],
                SubLocationID: [this.CourierRequestDetailUI.CourierRequestDetails.SubLocationID, []],
                SubLocationName: [this.CourierRequestDetailUI.CourierRequestDetails.SubLocationName, []],
                FloorNumber: [this.CourierRequestDetailUI.CourierRequestDetails.FloorNumber, []],
                LocationOwnerGPN: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.LocationOwnerGPN, disabled: true }, []],
                LocationOwnerName: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.LocationOwnerName, disabled: true }, [Validators.required]],
                CourierCompanyID: [this.CourierRequestDetailUI.CourierRequestDetails.CourierCompanyID, []],
                CourierCompanyName: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.CourierCompanyName, disabled: !this.RolesAndActions.CanEditCourierDetails }, []],
                SenderName: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.SenderName, disabled: !this.RolesAndActions.CanEditCourierDetails },
                [Validators.required, Validators.maxLength(150), CustomValidators.SpecialCharacterCheck]],
                EmpLPN: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpLPN, disabled: true }, []],
                EmpGPN: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpGPN, disabled: true }, []],
                EmpName: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpName, disabled: true }, []],
                EmpEmail: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpEmail, disabled: true }, []],
                EmpMobile: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpMobile, disabled: true }, []],
                EmpOffPhone: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpOffPhone, disabled: !this.RolesAndActions.CanEditCourierMailRoomProcess }, [Validators.required, Validators.maxLength(17), CustomValidators.PhoneFormat]],
                EmpFirmID: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpFirmID, disabled: true }, []],
                EmpFirm: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpFirm, disabled: true }, []],
                EmpBU: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpBU, disabled: true }, []],
                EmpBUDescr: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpBUDescr, disabled: true }, []],
                EmpOU: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpOU, disabled: true }, []],
                EmpOUDescr: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpOUDescr, disabled: true }, []],
                EmpOffLocationID: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpOffLocationID, disabled: true }, []],
                EmpOffDescription: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.EmpOffDescription, disabled: true }, []],
                HandedOverToGPN: [this.CourierRequestDetailUI.CourierRequestDetails.HandedOverToGPN, []],
                HandedOverToName: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.HandedOverToName, disabled: !this.RolesAndActions.CanEditReceiverInformation }, []],
                AdditionalComments: [{ value: this.CourierRequestDetailUI.CourierRequestDetails.AdditionalComments, disabled: !this.RolesAndActions.CanEditCourierDetails }, [Validators.maxLength(50), CustomValidators.SpecialCharacterCheck]],
                RejectionReason: [this.CourierRequestDetailUI.CourierRequestDetails.RejectionReason, []],
                StatusID: [this.CourierRequestDetailUI.CourierRequestDetails.StatusID, []],
                CreatedBy: [this.CourierRequestDetailUI.CourierRequestDetails.CreatedBy, []],
                CreatedByName: [this.CourierRequestDetailUI.CourierRequestDetails.CreatedByName, []],
                CreatedOn: [new Date(this.CourierRequestDetailUI.CourierRequestDetails.CreatedOn), []],
                ModifiedByGPN: [this.CourierRequestDetailUI.CourierRequestDetails.ModifiedByGPN, []],
                ModifiedOn: [new Date(this.CourierRequestDetailUI.CourierRequestDetails.ModifiedOn), []],
                HandedOverDate: [{ value: new Date(this.CourierRequestDetailUI.CourierRequestDetails.HandedOverDate), disabled: true }, []],
                DeliveredDate: [new Date(this.CourierRequestDetailUI.CourierRequestDetails.DeliveredDate), []],
                IsActive: [this.CourierRequestDetailUI.CourierRequestDetails.IsActive, []],
                RequestNumber: [this.CourierRequestDetailUI.CourierRequestDetails.RequestNumber, []],
                StatusDesc: [this.CourierRequestDetailUI.CourierRequestDetails.StatusDesc, []],
                SelectedCourierCompany: [{ value: this.SelectedCourierCompany, disabled: !this.RolesAndActions.CanEditCourierDetails }, [Validators.required]],
                SelectedSenderOffice: [{ value: this.SelectedSenderOffice, disabled: !this.RolesAndActions.CanEditCourierDetails }, [Validators.required]],
                SelectedLocation: [{ value: this.SelectedLocation, disabled: !this.RolesAndActions.CanEditCourierDetails }, [Validators.required]],
                SelectedSubLocation: [{ value: this.SelectedSubLocation, disabled: !this.RolesAndActions.CanEditCourierDetails }, [Validators.required]],
                SelectedFloor: [{ value: this.SelectedFloor, disabled: !this.RolesAndActions.CanEditCourierMailRoomProcess }, [Validators.required]],
                SelectedEmp: [{ value: this.SelectedEmp, disabled: !this.RolesAndActions.CanEditCourierDetails }, [Validators.required]]
            });
            if (this.CourierRequestDetailUI.CourierRequestDetails.StatusID === 1) {
                this.courierForm.controls["EmpOffPhone"].setValidators([Validators.required, Validators.maxLength(17), CustomValidators.PhoneFormat]);
            }
            if (this.CourierRequestDetailUI.CourierRequestDetails.StatusID === 2) {
                this.courierForm.controls["HandedOverToName"].setValidators([Validators.required, Validators.maxLength(50), CustomValidators.SpecialCharacterCheck]);
            }

            if (this.CourierRequestDetailUI.CourierRequestDetails.CourierCompanyID === 1) {
                this.OtherCourierCompanyRequired = true;
                this.courierForm.controls["CourierCompanyName"].setValidators([Validators.required, Validators.maxLength(50), CustomValidators.SpecialCharacterCheck]);
            }
            else {
                this.OtherCourierCompanyRequired = false;
                this.courierForm.controls["CourierCompanyName"].setValidators(null);
            }

            this.OnClickPacketReceivedType();
        });
    }
    filterEmployeeSingle(event: any) {
        const query = event.query;
        if (query) {
            if (query.length >= 3) {
                let EmpSearchParameter = {
                    SearchType: 0, EmpGPN: '',
                    EmpName: query,
                    LocationID: this.courierForm.controls["SelectedLocation"] === null ? '0' : this.courierForm.controls["SelectedLocation"].value.LocationID,
                    SubLocationID: this.courierForm.controls["SelectedSubLocation"] === null ? '' : this.courierForm.controls["SelectedSubLocation"].value.SubLocationID
                };
                this.gservice.PostData('GetEmployeeDetailsByName/', EmpSearchParameter)
                    .subscribe((data: any) => {
                        this.EmpDetails = [];
                        if (data.length > 0) {
                            this.EmpDetails = data;
                        }
                    });
            }
        }
    }
    OnClickPacketReceivedType() {
        this.IsSubAWBRequired = false;
        this.IsReceivedLocationRequired = false;
        this.courierForm.controls["SubAWBNumber"].setErrors(null);
        this.courierForm.controls["SubAWBNumber"].setAsyncValidators(null);
        let selected = this.courierForm.controls["PacketReceivedType"].value;
        if (selected == 1) {
            this.IsSubAWBRequired = true;
            this.IsReceivedLocationRequired = true;
            this.courierForm.controls["SubAWBNumber"].setValidators([Validators.required, Validators.maxLength(30), CustomValidators.SpecialCharacterCheck]);

            // this.courierForm.controls["SubAWBNumber"].setAsyncValidators([CustomValidators.IsUniqueSubAWBNumber(this.gservice)]);
            
            // this.courierForm.controls["SubAWBNumber"].valueChanges.pipe(debounceTime(5000)).subscribe(value => {
            //     debugger
            //     // this.courierForm.controls["SubAWBNumber"].setAsyncValidators([CustomValidators.IsUniqueSubAWBNumber(this.gservice)]);
            // })
            
        }
    }
    onChangeAWBNumber() {
        let selected = this.courierForm.controls["PacketReceivedType"].value;
        if (selected == 1) {
            this.courierForm.controls['SubAWBNumber'].updateValueAndValidity();
        }
        else {
            this.courierForm.controls["SubAWBNumber"].setErrors(null);
            this.courierForm.controls["SubAWBNumber"].setAsyncValidators(null);
        }
    }
    onCourierCompanyChange(event) {
        this.OtherCourierCompanyRequired = false;
        this.courierForm.controls["CourierCompanyName"].setValidators(null);
        if (event.value.CourierCompanyID === 1) {
            this.OtherCourierCompanyRequired = true;
            this.courierForm.controls["CourierCompanyName"].setValidators([Validators.required, Validators.maxLength(50), CustomValidators.SpecialCharacterCheck]);
        }
    }
    onLocationChange(event) {
        this.SubLocationList = [];
        this.SelectedSubLocation = null;
        this.courierForm.controls["LocationOwnerGPN"].setValue("");
        this.courierForm.controls["LocationOwnerName"].setValue("");
        this.gservice.GetData("GetSubLocation/" + event.value.LocationID).subscribe((data: any) => {
            this.courierForm.controls["SelectedSubLocation"].setValue(data[0]);
            this.SubLocationList = data;
        });
    }
    onSubLocationChange(event) {
        this.LocationOwnerRequired = true;
        this.FloorList = [];
        this.SelectedFloor = null;
        this.courierForm.controls["LocationOwnerGPN"].setValue("");
        this.courierForm.controls["LocationOwnerName"].setValue("");
        if (event.value.SubLocationID.length > 0) {
            this.gservice.GetData("GetFloor/" + this.courierForm.controls["SelectedLocation"].value.LocationID + "/" + event.value.SubLocationID).subscribe((data: any) => {
                this.courierForm.controls["SelectedFloor"].setValue(data[0]);
                this.FloorList = data;
            });
            this.gservice.GetData("GetLocationOwner/" + this.courierForm.controls["SelectedLocation"].value.LocationID + "/" + event.value.SubLocationID).subscribe((data: any) => {
                this.courierForm.controls["LocationOwnerGPN"].setValue(data.LocationOwnerGPN);
                this.courierForm.controls["LocationOwnerName"].setValue(data.LocationOwnerName);
                if (this.courierForm.controls["LocationOwnerGPN"].value.length > 0) {
                    this.LocationOwnerRequired = false;
                }
            });
        }
    }
    OnEmployeeSelect(event: any) {
        let SelectedEmployee = this.EmpDetails.find(i => i.EmpGPN === event.EmpGPN);
        this.courierForm.controls["SelectedEmp"].setValue({
            EmpLPN: SelectedEmployee.EmpLPN,
            EmpGPN: SelectedEmployee.EmpGPN,
            EmpName: SelectedEmployee.EmpName,
            EmpEmail: SelectedEmployee.EmpEmail,
            EmpMobile: SelectedEmployee.EmpMobile,
            EmpOffPhone: SelectedEmployee.EmpOffPhone,
            EmpOU: SelectedEmployee.EmpOU,
            EmpOUDescr: SelectedEmployee.EmpOUDescr,
            EmpBU: SelectedEmployee.EmpBU,
            EmpBUDescr: SelectedEmployee.EmpBUDescr,
            EmpFirmID: SelectedEmployee.EmpFirmID,
            EmpFirm: SelectedEmployee.EmpFirm,
            EmpOffLocationID: SelectedEmployee.EmpOffLocationID,
            EmpOffDescription: SelectedEmployee.EmpOffDescription
        });
        this.courierForm.controls['EmpLPN'].setValue(SelectedEmployee.EmpLPN);
        this.courierForm.controls['EmpGPN'].setValue(SelectedEmployee.EmpGPN);
        this.courierForm.controls['EmpName'].setValue(SelectedEmployee.EmpName);
        this.courierForm.controls['EmpEmail'].setValue(SelectedEmployee.EmpEmail);
        this.courierForm.controls['EmpMobile'].setValue(SelectedEmployee.EmpMobile);
        this.courierForm.controls['EmpOffPhone'].setValue(SelectedEmployee.EmpOffPhone);
        this.courierForm.controls['EmpFirmID'].setValue(SelectedEmployee.EmpFirmID);
        this.courierForm.controls['EmpFirm'].setValue(SelectedEmployee.EmpFirm);
        this.courierForm.controls['EmpBU'].setValue(SelectedEmployee.EmpBU);
        this.courierForm.controls['EmpBUDescr'].setValue(SelectedEmployee.EmpBUDescr);
        this.courierForm.controls['EmpOU'].setValue(SelectedEmployee.EmpOU);
        this.courierForm.controls['EmpOUDescr'].setValue(SelectedEmployee.EmpOUDescr);
        this.courierForm.controls['EmpOffLocationID'].setValue(SelectedEmployee.EmpOffLocationID);
        this.courierForm.controls['EmpOffDescription'].setValue(SelectedEmployee.EmpOffDescription);
    }
    OpenAuditTrail() {
        this.ViewAuditTrail = true;
    }
    Submit(action: number) {
        let successMessage: string = "";
        let canSubmitToDB: boolean = false;
        let dataTosubmit: any = this.courierForm.getRawValue();
        if (action == Status.Submitted && this.IsValidSubmit()) {
            dataTosubmit.StatusID = Status.Submitted;
            dataTosubmit.ReceivedDate = null;
            dataTosubmit.HandedOverDate = null;
            dataTosubmit.DeliveredDate = null;
            canSubmitToDB = true;
            successMessage = "Request successfully submitted."
        }
        else if (action == Status.SentForDelivery && this.IsValidSentForDelivery()) {
            dataTosubmit.FloorNumber = this.courierForm.controls["SelectedFloor"].value.FloorNumber;
            dataTosubmit.StatusID = Status.SentForDelivery;
            dataTosubmit.HandedOverDate = null;
            dataTosubmit.DeliveredDate = null;
            canSubmitToDB = true;
            successMessage = "Request successfully sent for delivery."
        }
        else if (action == Status.Delivered && this.IsValidMarkDelivered()) {
            dataTosubmit.StatusID = Status.Delivered;
            dataTosubmit.DeliveredDate = null;
            canSubmitToDB = true;
            successMessage = "Request successfully delivered."
        }
        else if (action == Status.Discarded) {
            dataTosubmit.StatusID = Status.Discarded;
            canSubmitToDB = true;
            successMessage = "Request successfully discarded."
        }
        else if (action == Status.Updated) {
            if (this.courierForm.controls["SelectedFloor"] != undefined && this.courierForm.controls["SelectedFloor"] != null && this.courierForm.controls["SelectedFloor"].value.FloorNumber.length > 0) {
                dataTosubmit.FloorNumber = this.courierForm.controls["SelectedFloor"].value.FloorNumber;
            }
            dataTosubmit.StatusID = Status.Updated;
            canSubmitToDB = true;
            successMessage = "Request successfully updated."
        }
        if (canSubmitToDB) {
            if (this.RolesAndActions.CanUpdate == true || this.RolesAndActions.CanSubmit == true) {
                dataTosubmit.LocationID = this.courierForm.controls["SelectedLocation"].value.LocationID;
                dataTosubmit.LocationName = this.courierForm.controls["SelectedLocation"].value.LocationName;
                dataTosubmit.SubLocationID = this.courierForm.controls["SelectedSubLocation"].value.SubLocationID;
                dataTosubmit.SubLocationName = this.courierForm.controls["SelectedSubLocation"].value.SubLocationName;
                dataTosubmit.ReceivedFromOfficeID = this.courierForm.controls["SelectedSenderOffice"].value.SubLocationID;
                dataTosubmit.ReceivedFromOfficeName = this.courierForm.controls["SelectedSenderOffice"].value.SubLocationName;

                if (dataTosubmit.PacketReceivedType != 1) {
                    dataTosubmit.SubAWBNumber = "";
                    dataTosubmit.ReceivedFromOfficeID = "";
                    dataTosubmit.ReceivedFromOfficeName = ""
                }
                dataTosubmit.CourierCompanyID = this.courierForm.controls["SelectedCourierCompany"].value.CourierCompanyID;
                if (dataTosubmit.SelectedCourierCompany.CourierCompanyID != 1) {
                    dataTosubmit.CourierCompanyName = this.courierForm.controls["SelectedCourierCompany"].value.CourierCompanyName;
                }

            }
            this.gservice.PostData('CourierRequestDetails', dataTosubmit).subscribe((data: any) => {
                this.alertService.notify('success', 'Success', successMessage);
                this.router.navigate(['home/list', this.gservice.EncryptData(data)]);
            });
        }
    }
    IsValidSubmit(): boolean {
        let IsValid: boolean = true;
        if (this.courierForm.invalid) {
            IsValid = false;
        }
        else if (this.courierForm.controls["PacketReceivedType"].value == 1 && (this.courierForm.controls["SelectedSenderOffice"] == undefined || this.courierForm.controls["SelectedSenderOffice"] == null || this.courierForm.controls["SelectedSenderOffice"].value.SubLocationID.length <= 0)) {
            IsValid = false;
        }
        else if (this.courierForm.controls["SelectedCourierCompany"] == undefined || this.courierForm.controls["SelectedCourierCompany"] == null || this.courierForm.controls["SelectedCourierCompany"].value.CourierCompanyID <= 0) {
            IsValid = false;
        }
        else if (this.courierForm.controls["SelectedSubLocation"] == undefined || this.courierForm.controls["SelectedSubLocation"] == null || this.courierForm.controls["SelectedSubLocation"].value.SubLocationID.length <= 0) {
            IsValid = false;
        }
        else if (this.courierForm.controls["LocationOwnerName"] == undefined || this.courierForm.controls["LocationOwnerName"] == null || this.courierForm.controls["LocationOwnerName"].value.length <= 0) {
            IsValid = false;
        }
        else if (this.courierForm.controls["SelectedEmp"] == undefined || this.courierForm.controls["SelectedEmp"] == null || this.courierForm.controls["SelectedEmp"].value.EmpGPN.length <= 0) {
            IsValid = false;
        }
        return IsValid;
    }
    IsValidSentForDelivery(): boolean {
        let IsValid: boolean = true;
        if (!this.IsValidSubmit()) {
            IsValid = false;
        }
        else if (this.courierForm.controls["SelectedFloor"] == undefined || this.courierForm.controls["SelectedFloor"] == null || this.courierForm.controls["SelectedFloor"].value.FloorNumber.length <= 0) {
            IsValid = false;
        }
        return IsValid;
    }
    IsValidMarkDelivered() {
        let IsValid: boolean = true;
        if (!this.IsValidSentForDelivery()) {
            IsValid = false;
        }
        return IsValid;
    }
    IsValidUpdate() {
        let IsValid: boolean = true;
        if (this.CourierRequestDetailUI.RolesAndActions.CanSendForDelivery) {
            if (!this.IsValidSubmit()) {
                IsValid = false;
            }
        }
        else if (this.CourierRequestDetailUI.RolesAndActions.CanMarkDelivered) {
            if (!this.IsValidSentForDelivery()) {
                IsValid = false;
            }
        }
        return IsValid;
    }
    get f() {
        return this.courierForm.controls;
    }
    CanSelectedViewDetails(): boolean {
        let _selected: boolean = false;
        if (this.RolesAndActions.CanSubmit == true) {
            _selected = true;
        }
        else if (this.RolesAndActions.CanView && (!this.RolesAndActions.CanSendForDelivery && !this.RolesAndActions.CanMarkDelivered)) {
            _selected = true;
        }
        return _selected;
    }
    showConfirm(confirmtype: string) {
        this.confirmtype = confirmtype;
        let confirmMessage: string = "";
        if (this.confirmtype == "exit") {
            confirmMessage = "Are you sure you want to exit?";
        }
        else if (this.confirmtype == "discard") {
            confirmMessage = "Are you sure you want to discard?"
        }
        else if (this.confirmtype == "update") {
            confirmMessage = "Are you sure you want to update?"
        }
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: confirmMessage, detail: '' });
    }
    onConfirm(confirmtype: string) {
        this.messageService.clear('c');
        if (this.confirmtype == "exit") {
            let RequestType: string = "";
            RequestType = this.appToken.getRequestType();
            if (RequestType.length > 0) {
                if (RequestType == "1") {
                    this.router.navigate(['/home/list']);
                }
                else if (RequestType == "2") {
                    this.router.navigate(['/home/listPending', '2']);
                }
                else {
                    this.router.navigate(['/home/dashboard']);
                }
            }
            else {
                this.router.navigate(['/home/dashboard']);
            }
        }
        else if (this.confirmtype == "discard") {
            this.Submit(Status.Discarded);
        }
        else if (this.confirmtype == "update") {
            this.Submit(Status.Updated);
        }
    }
    onReject() {
        this.messageService.clear('c');
        if (this.confirmtype == "update") {
            this.InitializeValues();
        }
    }
}
