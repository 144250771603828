import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type Severities = 'success' | 'info' | 'warn' | 'error';
@Injectable({ providedIn: 'root' })
export class AlertService {
    notificationChange: Subject<Object> = new Subject<Object>();
    notify(severity: Severities, summary: string, detail: string, redirectRoute: string="") {
        this.notificationChange.next({ severity, summary, detail, redirectRoute });
    }
}