import { Component, OnInit } from '@angular/core';
import { DataService } from '../../common/data.service';
import { CarouselModule } from 'primeng/carousel';
import { Router } from '@angular/router';
import { IContactDetailsForLoginUser } from '../../model/courier-request-detail';
@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    ContactDetailsForLoginUser: Array<IContactDetailsForLoginUser> = null;
    AdminContactDetails: IContactDetailsForLoginUser = null;
    CurrentLocationDetails: IContactDetailsForLoginUser = null;
    LocationPersons: Array<IContactDetailsForLoginUser> = null;
    constructor(private gservice: DataService, private router: Router) { }
    ngOnInit() {
        this.GetContactDetailsForLoginUser();
    }
    GetContactDetailsForLoginUser() {
        this.gservice.GetData("GetContactDetailsForLoginUser").subscribe(
            (data: any) => {
                this.ContactDetailsForLoginUser = data;
                if (this.ContactDetailsForLoginUser.length > 0) {
                    this.CurrentLocationDetails = this.ContactDetailsForLoginUser.find(x => x.ContactType == "CurrentLocation");
                    this.AdminContactDetails = this.ContactDetailsForLoginUser.find(x => x.ContactType == "SystemAdmin");
                    this.LocationPersons = this.ContactDetailsForLoginUser.filter(x => x.ContactType == "LocationPerson");                   
                }
            }
        );
    }
}
