export const environment = {
  production: false,
  appcode: 'INCMS',

  //local
  // serviceBaseUrl: 'http://localhost:56392/api/',
  // tokenPath: 'http://localhost:56392/api/Token',

  // azure qa1
  // serviceBaseUrl: 'https://inqa1.ey.com/mailroom/mailroomApi/api/',
  // tokenPath: 'https://inqa1.ey.com/mailroom/mailroomApi/api/Token',

  // azure UAT
  serviceBaseUrl: 'https://inmailroom-uat.ey.net/mailroomApi/api/',
  tokenPath: 'https://inmailroom-uat.ey.net/mailroomApi/api/Token',

  logPageAnalytics: 'N',
  pageAnalyticsUrl: 'https://inpageanalytics.ey.net/Pages/InsertPageAnalytic.aspx',

  msalConfig: {
    authURL: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c/oauth2/v2.0/authorize',
    tokenURL: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c/oauth2/v2.0/token',
    clientId: 'e26544c6-421b-4acd-92fd-7cc596aa95ac', // uat
    // clientId: '26972097-0b03-4df4-8e26-b754cc24f588', // qa1

    // redirectUri: 'http://localhost:4200', //local
    // redirectUri: 'https://inqa1.ey.com/mailroom' // qa1
    redirectUri : 'https://inmailroom-uat.ey.net' // UAT
  },
};
import 'zone.js/plugins/zone-error';