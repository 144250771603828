import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppTokenHandler } from '../../common/app-token-handler';
import { DataService } from 'src/app/common/data.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {
    constructor(private appToken: AppTokenHandler, private route: Router, private dataService: DataService) { }
    ngOnInit() {
        this.dataService.PostData('Logout', null).subscribe((data: any) => {
            this.appToken.clearSessionToken();
        }, (error) => {
            this.appToken.clearSessionToken();
        });
    };
    Login() {
        // window.location.href = '/'
        this.route.navigate(['/']);
    };
}
