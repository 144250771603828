import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-bad-request',
    templateUrl: './bad-request.component.html',
    styleUrls: ['./bad-request.component.css']
})
export class BadRequestComponent implements OnInit {

    constructor(private route: Router) { }
    ngOnInit() {
    }
    Login() {
        this.route.navigate(['/login']);
    };
}
