import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError, of, from } from 'rxjs';

import { switchMap, catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class AzureAuthService {
    constructor(private _route: ActivatedRoute,
        private _http: HttpClient) { }

    private acquireToken(code: string, sessionState?: string): Observable<string | null> {

        const codeVerifier = localStorage.getItem('codeVerifier');
        const codeChallenge = localStorage.getItem('codeChallenge');

        // const codeVerifier = sessionStorage.getItem('codeVerifier');
        // const codeChallenge = sessionStorage.getItem('codeChallenge');

        if (!codeVerifier || !codeChallenge) {
            throw new Error('Missing code verifier or code challenge');
        }

        var client_id = environment.msalConfig.clientId;
        var redirect_uri = environment.msalConfig.redirectUri

        const formData = new FormData();
        formData.append('client_id', client_id);
        formData.append('redirect_uri', redirect_uri);
        formData.append('code', code);
        formData.append('grant_type', 'authorization_code');
        formData.append('code_verifier', codeVerifier);
        formData.append('code_challenge', codeChallenge);
        formData.append('code_challenge_method', 'S256');

        if (sessionState) {
            formData.append('state', sessionState);
        }
        var tokenUrl = environment.msalConfig.tokenURL;

        return this._http.post<any>(tokenUrl, formData).pipe(
            map((response) => {
                return response.access_token
            }
            ),
            catchError(error => {
                console.error('Error acquiring token', error);
                return of(null);
            })
        );
    }

    handleQueryParameters(): Observable<string | null> {
        return this._route.queryParams.pipe(
            switchMap(params => {
                if (params && params['code'] && params['session_state']) {
                    return this.acquireToken(params['code'], params['session_state']);
                } else {
                    return this.makeAuthRequest().then(url => {
                        window.location.href = url;
                        return null;
                    });
                }
            }),
            catchError(error => throwError(error))
        );
    }

    private async makeAuthRequest(): Promise<string> {
        const codeVerifier = this.generateRandomCodeVerifier();
        const codeChallenge = await this.createCodeChallenge(codeVerifier);

        localStorage.setItem('codeVerifier', codeVerifier);
        localStorage.setItem('codeChallenge', codeChallenge);

        // sessionStorage.setItem('codeVerifier', codeVerifier);
        // sessionStorage.setItem('codeChallenge', codeChallenge);

        const url = `${environment.msalConfig.authURL}?client_id=${environment.msalConfig.clientId}&redirect_uri=${environment.msalConfig.redirectUri}&scope=user.read&response_type=code&response_mode=query&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        return url;
    }

    private generateRandomCodeVerifier(): string {
        const array = new Uint32Array(56 / 2);
        window.crypto.getRandomValues(array);
        return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
    }

    private async createCodeChallenge(codeVerifier: string): Promise<string> {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window.crypto.subtle.digest("SHA-256", data);
        return this.base64urlencode(digest);
    }

    private base64urlencode(a: ArrayBuffer): string {
        const bytes = new Uint8Array(a);
        const byteArr = Array.from(bytes); // Convert to regular array
        return btoa(String.fromCharCode(...byteArr)) // Spread the regular array
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    }
}
