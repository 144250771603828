import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProgressService {
    notificationChange: Subject<Object> = new Subject<Object>();
    ChangeProgressStatus(status: boolean) {
        this.notificationChange.next(status);
    }
}