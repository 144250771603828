import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AppTokenHandler {
    public access_token_key = 'access_token';
    access_token_value = '';
    loginUserInfo: UserInfo;
    azure_token_value: string = '';
    public azure_token_key = 'azure_token';

    constructor() {
    }

    getSessionToken() {
        if (sessionStorage.length > 0) {
            this.access_token_value = sessionStorage.getItem(this.access_token_key);
        }
        if (this.access_token_value == null) {
            this.access_token_value = '';
        }
        return this.access_token_value;
    }
    setSessionToken(loginData) {
        this.access_token_value = loginData.access_token;
        sessionStorage.setItem('UserInfo', JSON.stringify(loginData.UserInfo));
        sessionStorage.setItem(this.access_token_key, this.access_token_value);
    }
    clearSessionToken() {
        sessionStorage.removeItem(this.access_token_key);
        sessionStorage.clear();
        localStorage.clear();
        this.deleteAllCookies();
    }
    getLoginUserInfo(): UserInfo {
        this.loginUserInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
        return this.loginUserInfo;
    }
    IsInRole(role: string): boolean {
        let validRole: boolean = false;
        let loginInfo: UserInfo = this.getLoginUserInfo();
        let roles: string[] = loginInfo.Roles.split(',');
        if (roles.find(x => x.indexOf(role) >= 0)) {
            validRole = true;
        }
        return validRole;
    }
    setRequestType(RequestType: string) {
        sessionStorage.removeItem('RequestType');
        sessionStorage.setItem('RequestType', RequestType);
    }
    getRequestType() {
        let RequestType: string = "";
        if (sessionStorage.getItem('RequestType') != null)
            RequestType = sessionStorage.getItem('RequestType');;
        return RequestType;
    }

    setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    deleteCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    deleteAllCookies() {
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        });
    }

    setAzureTokenInCookie(azureToken: string): void {
        if (azureToken) {
            this.azure_token_value = azureToken;
            this.setCookie(this.azure_token_key, this.azure_token_value, 7);
        }
    }
}

export class UserInfo {
    UserId: string;
    EmpGPN: string;
    EmpEmail: string;
    EmpName: string;
    Roles: string;
    ExpiresUtc: Date;
}
