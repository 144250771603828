// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.colorgraph {
    height: 5px;
    border-top: 0;
    background: #c4e17f;
    border-radius: 5px;
    background-image: linear-gradient(to right,#c4e17f,#c4e17f 12.5%,#f7fdca 12.5%,#f7fdca 25%,#fecf71 25%,#fecf71 37.5%,#f0776c 37.5%,#f0776c 50%,#db9dbe 50%,#db9dbe 62.5%,#c49cde 62.5%,#c49cde 75%,#669ae1 75%,#669ae1 87.5%,#62c2e4 87.5%,#62c2e4);
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImR1bW15LWxvZ2luLmNvbXBvbmVudC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7SUFDSSxXQUFXO0lBQ1gsYUFBYTtJQUNiLG1CQUFtQjtJQUNuQixrQkFBa0I7SUFDbEIsbVBBQW1QO0FBQ3ZQIiwiZmlsZSI6ImR1bW15LWxvZ2luLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29sb3JncmFwaCB7XHJcbiAgICBoZWlnaHQ6IDVweDtcclxuICAgIGJvcmRlci10b3A6IDA7XHJcbiAgICBiYWNrZ3JvdW5kOiAjYzRlMTdmO1xyXG4gICAgYm9yZGVyLXJhZGl1czogNXB4O1xyXG4gICAgYmFja2dyb3VuZC1pbWFnZTogbGluZWFyLWdyYWRpZW50KHRvIHJpZ2h0LCNjNGUxN2YsI2M0ZTE3ZiAxMi41JSwjZjdmZGNhIDEyLjUlLCNmN2ZkY2EgMjUlLCNmZWNmNzEgMjUlLCNmZWNmNzEgMzcuNSUsI2YwNzc2YyAzNy41JSwjZjA3NzZjIDUwJSwjZGI5ZGJlIDUwJSwjZGI5ZGJlIDYyLjUlLCNjNDljZGUgNjIuNSUsI2M0OWNkZSA3NSUsIzY2OWFlMSA3NSUsIzY2OWFlMSA4Ny41JSwjNjJjMmU0IDg3LjUlLCM2MmMyZTQpO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/out-of-application/dummy-login/dummy-login.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,mPAAmP;AACvP;;AAEA,ozBAAozB","sourcesContent":[".colorgraph {\r\n    height: 5px;\r\n    border-top: 0;\r\n    background: #c4e17f;\r\n    border-radius: 5px;\r\n    background-image: linear-gradient(to right,#c4e17f,#c4e17f 12.5%,#f7fdca 12.5%,#f7fdca 25%,#fecf71 25%,#fecf71 37.5%,#f0776c 37.5%,#f0776c 50%,#db9dbe 50%,#db9dbe 62.5%,#c49cde 62.5%,#c49cde 75%,#669ae1 75%,#669ae1 87.5%,#62c2e4 87.5%,#62c2e4);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
