// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aligncentre{
    text-align:center
}
.btn-primary{
    background-color:#797878
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRhc2hib2FyZC5jb21wb25lbnQuY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0lBQ0k7QUFDSjtBQUNBO0lBQ0k7QUFDSiIsImZpbGUiOiJkYXNoYm9hcmQuY29tcG9uZW50LmNzcyIsInNvdXJjZXNDb250ZW50IjpbIi5hbGlnbmNlbnRyZXtcclxuICAgIHRleHQtYWxpZ246Y2VudGVyXHJcbn1cclxuLmJ0bi1wcmltYXJ5e1xyXG4gICAgYmFja2dyb3VuZC1jb2xvcjojNzk3ODc4XHJcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/inside-of-application/dashboard/dashboard.component.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI;AACJ;AACA,4YAA4Y","sourcesContent":[".aligncentre{\r\n    text-align:center\r\n}\r\n.btn-primary{\r\n    background-color:#797878\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
