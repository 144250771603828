import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { AppTokenHandler } from '../common/app-token-handler';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DataService } from '../common/data.service';

@Injectable()
export class AuthGuardService  {
    constructor(private appToken: AppTokenHandler, private route: Router, private gservice: DataService) {
        this.route.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this.LogPageAnalytics();
            }
        }​​​​​​​);
    }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.appToken.getSessionToken().length > 0) {
            return true;
        }
        this.route.navigate(['/unauthorize']);
    };
    LogPageAnalytics() {
        if (environment.logPageAnalytics == 'Y') {
            let _url:string = environment.pageAnalyticsUrl + '?PageTitle=' + window.location.href.replace('#/', '') + '&ApplicationCode=' + environment.appcode;
            this.gservice
                .LogPageAnalytics(_url)
                .subscribe(data => {
                }, (error) => {
                });
        }
    }
}