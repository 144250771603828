import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './common/auth-guard.service';
import { HomeComponent } from '../app/inside-of-application/home/home.component';
import { DashboardComponent } from '../app/inside-of-application/dashboard/dashboard.component';
import { BadRequestComponent } from '../app/out-of-application/bad-request/bad-request.component';
import { PageNotFoundComponent } from '../app/out-of-application/page-not-found/page-not-found.component';
import { LogoutComponent } from '../app/out-of-application/logout/logout.component';
import { UnAuthorizeComponent } from '../app/out-of-application/un-authorize/un-authorize.component';
import { SessionExpiredComponent } from './out-of-application/session-expired/session-expired.component';
import { LoginComponent } from './out-of-application/login/login.component';
import { DummyLoginComponent } from './out-of-application/dummy-login/dummy-login.component';
import { CreateNewRequestComponent } from '../app/inside-of-application/create-new-request/create-new-request.component';
import { RequestDetailsListComponent } from '../app/inside-of-application/request-details-list/request-details-list.component';
import { LocationOwnerMappingComponent } from './inside-of-application/location-owner-mapping/location-owner-mapping.component';
import { LocationFloorMappingComponent } from './inside-of-application/location-floor-mapping/location-floor-mapping.component';
import { ViewRequestComponent } from './out-of-application/view-request/view-request.component';


const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'login', component: LoginComponent
    },
    { path: 'ref/:Path', component: LoginComponent },
    { path: 'dummylogin', component: DummyLoginComponent },
    { path: 'logout', component: LogoutComponent },
    { path: 'unauthorize', component: UnAuthorizeComponent },
    { path: 'badrequest', component: BadRequestComponent },
    { path: 'pagenotfound', component: PageNotFoundComponent },
    { path: 'sessionexpired', component: SessionExpiredComponent },
    { path: 'view/:id', component: ViewRequestComponent },
    {
        path: 'home', component: HomeComponent, canActivate: [AuthGuardService],
        children: [
            {
                path: '', component: DashboardComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'location', component: LocationOwnerMappingComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'floor', component: LocationFloorMappingComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'new/:id', component: CreateNewRequestComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'new', component: CreateNewRequestComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'listPending/:type:/:id', component: RequestDetailsListComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'listPending/:type', component: RequestDetailsListComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'list/:id', component: RequestDetailsListComponent, canActivate: [AuthGuardService]
            },
            {
                path: 'list', component: RequestDetailsListComponent, canActivate: [AuthGuardService]
            }
        ]
    },
    { path: '**', redirectTo: 'pagenotfound' }
];

@NgModule({
    // // imports: [RouterModule.forRoot(routes, { useHash: false })],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuardService]
})
export class AppRoutingModule {
}
