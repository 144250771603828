import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams, HttpClientJsonpModule } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppTokenHandler } from '../common/app-token-handler';
import SimpleCrypto from "simple-crypto-js";

@Injectable()
export class DataService {
    private apiUrl: string;
    private secretKey: string = environment.appcode;
    constructor(private appToken: AppTokenHandler, private http: HttpClient) {
        this.apiUrl = environment.serviceBaseUrl;
    }
    Login() {
        const httpLoginPostOptions = {
            withCredentials: true
        };
        return this.http.post(environment.tokenPath, null, httpLoginPostOptions).pipe(catchError(this.handleError));
    }


    // LoginWithEmail(Email: string) {
    //     const body: any = null;
    //     let params = new HttpParams();
    //     params = params.append('Email', Email);
    //     const httpLoginPostOptions = {
    //         withCredentials: true,
    //         params: params
    //     };
    //     return this.http.post(environment.tokenPath, JSON.stringify(body), httpLoginPostOptions).pipe(catchError(this.handleError));
    // }

    
    LoginWithToken(token: string) {
        var httpDataServiceOptions = {
            withCredentials: true,
            headers: new HttpHeaders({
                'AzureAccessToken': token,
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .post(environment.tokenPath, null, httpDataServiceOptions)
            .pipe(catchError(this.handleError));

    }

    LoginWithEmail(Email: string) {
        var httpDataServiceOptions = {
            withCredentials: true,
            headers: new HttpHeaders({
                'AccessEmail': Email,
                'Content-Type': 'application/json'
            })
        };
        return this.http
            .post(environment.tokenPath, null, httpDataServiceOptions)
            .pipe(catchError(this.handleError));
    }

    GetEmployeeByName(_url: string, empname: string) {
        empname = empname.replace(/ /g, '-');
        _url = environment.serviceBaseUrl + _url + empname;
        return this.http.get<any>(_url, { withCredentials: true });
    }

    GetDataDebounce(_url: string) {
        _url = environment.serviceBaseUrl + _url;
        return this.http.get(_url, { withCredentials: true }).pipe(debounceTime(2000));
    }

    GetData(_url: string) {
        _url = environment.serviceBaseUrl + _url;
        return this.http.get(_url, { withCredentials: true }).pipe(catchError(this.handleError));
    }
    GetDataById(_url: string, _id: string) {
        _url = environment.serviceBaseUrl + _url + "/" + _id;
        return this.http.get(_url, { withCredentials: true }).pipe(catchError(this.handleError));
    }
    PostData(_url: string, _postdata: any) {
        _url = environment.serviceBaseUrl + _url;
        return this.http.post(_url, _postdata, { withCredentials: true }).pipe(catchError(this.handleError));
    }
    LogPageAnalytics(url) {
        return this.http.jsonp(url, "JSONP_CALLBACK").pipe();
    }
    ContainsSpecialCharacters(searchString: string) {
        let IsValidString: boolean = true;
        if (searchString.length > 0) {
            let regex = /^[A-Za-z0-9 ]+$/;
            IsValidString = regex.test(searchString);
        }
        return (IsValidString == true ? false : true);
    }
    EncryptData(data: string): string {
        let simpleCrypto = new SimpleCrypto(this.secretKey);
        let chiperText = simpleCrypto.encrypt(data);
        return chiperText.toString();
    }
    DecryptData(data: string): string {
        let simpleCrypto = new SimpleCrypto(this.secretKey);
        let chiperText = simpleCrypto.decrypt(data);
        return chiperText.toString();
    }
    private handleError(error: Response) {
        return throwError(error);
    }
}

@Injectable()
export class UIBlockingStatus {
    private IsBlocked: BehaviorSubject<boolean>;
    constructor() {
        this.IsBlocked = new BehaviorSubject(false);
    }

    setStatus(_IsBlocked: boolean) {
        this.IsBlocked.next(_IsBlocked);
    }

    getStatus(): Observable<boolean> {
        return this.IsBlocked.asObservable();
    }

}