import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AppTokenHandler } from '../common/app-token-handler';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProgressService } from './progress.service';

@Injectable()
export class WinAuthInterceptor implements HttpInterceptor {
    constructor(private appToken: AppTokenHandler, private route: Router, private progressService: ProgressService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.indexOf('IsUniqueSubAWBNumber') >= 0) {
            this.progressService.ChangeProgressStatus(true);
        } else {
            this.progressService.ChangeProgressStatus(true);
        }
        
        if (req.url == environment.tokenPath || req.url.startsWith('https://login.microsoftonline.com')) {
            return next.handle(req)
                .pipe(
                    finalize(() => {
                        this.progressService.ChangeProgressStatus(false);
                    }),
                );
        }
        if (this.appToken.getSessionToken().length > 0) {
            const cloned_req = req.clone({
                headers: req.headers.set('access_token', this.appToken.getSessionToken())
            });
            return next.handle(cloned_req).pipe(finalize(() => {
                this.progressService.ChangeProgressStatus(false);
            }));
        } else {
            this.progressService.ChangeProgressStatus(false);
            this.route.navigate(['/out-of-application/unauthorize']);
        }
    }
}