import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService } from '../../common/alert.service';
import { DataService } from '../../common/data.service';
import { AppTokenHandler } from '../../common/app-token-handler';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-dummy-login',
    templateUrl: './dummy-login.component.html',
    styleUrls: ['./dummy-login.component.css']
})
export class DummyLoginComponent implements OnInit {
    constructor(private formBuilder: UntypedFormBuilder, private route: Router, private alertService: AlertService, private appToken: AppTokenHandler, private gservice: DataService) { };
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            appcode: [environment.appcode, Validators.required],
            email: ['', Validators.compose([Validators.required, Validators.email])]
        });
    };
    get f() { return this.loginForm.controls; }
    onSubmit() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.GetUserDetails();
    };
    RedirectBack() {
        this.route.navigate(['/home/dashboard']);
    };
    GetUserDetails() {
        this.gservice.LoginWithEmail(this.loginForm.value['email']).subscribe((data: any) => {
            this.appToken.clearSessionToken();
            this.appToken.setSessionToken(data);
            this.route.navigate(['/home']);
        },
            (error: Response) => {
                if (error.status <= 0) {
                    this.route.navigate(['/badrequest']);
                }
                else if (error.status == 401) {
                    this.route.navigate(['/unauthorize']);
                }
                else {
                    this.alertService.notify('error', 'Server Error', error.statusText);
                }
            });
    };
}
