import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppTokenHandler } from '../../common/app-token-handler';
import { environment } from '../../../environments/environment';

enum Roles {
    Admin = 1,
    SecurityTeamMember = 2,
    LocationOwner = 3,
    User = 4
}

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    menuitems: MenuItem[];
    useritems: MenuItem[];
    subitems: Array<{ label: string, icon: string, routerLink: string }> = [];
    constructor(private router: Router, private appToken: AppTokenHandler) {
    }
    ngOnInit() {
        this.BindMenuItems();
    }
    CreateNewRequest() {
        this.appToken.setRequestType('');
        this.router.navigate(['/home/new']);
    }
    BindMenuItems() {
        this.menuitems = [
            {
                label: 'Home', routerLink: '/home/dashboard'
            },
            {
                label: 'Courier Details',
                items: [
                    //{ label: 'Create New Request', routerLink: '/home/new' }
                    { label: 'Create New Request', command: () => this.CreateNewRequest() }
                ],
                visible: (this.appToken.IsInRole(Roles[Roles.Admin]) || this.appToken.IsInRole(Roles[Roles.SecurityTeamMember]) || this.appToken.IsInRole(Roles[Roles.LocationOwner]))
            },
            {
                label: 'Views',
                items: [
                    {
                        label: 'Pending for Update', routerLink: ['/home/listPending', '2'],
                        visible: (this.appToken.IsInRole(Roles[Roles.Admin]) || this.appToken.IsInRole(Roles[Roles.LocationOwner]))
                    },
                    { label: 'View Requests', routerLink: '/home/list' }
                ]
            },
            {
                label: 'Masters',
                items: [
                    { label: 'Location Owner Mapping', routerLink: '/home/location' },
                    { label: 'Location & Floor Mapping', routerLink: '/home/floor' }
                ],
                visible: (this.appToken.IsInRole(Roles[Roles.Admin]))
            }
        ];

        if (!environment.production) {
            this.subitems.push({ label: 'Run as different user', icon: 'fa fa-unlock', routerLink: '/dummylogin' });
        }
        this.subitems.push({ label: 'Logout', icon: 'fa fa-sign-out', routerLink: '/logout' });
        this.useritems = [
            {
                label: this.appToken.getLoginUserInfo().EmpName, icon: 'fa fa-user',
                items: this.subitems
            },
        ];

    };
}
