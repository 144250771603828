import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../common/data.service';
import { AppTokenHandler } from '../../common/app-token-handler';
import { AlertService } from '../../common/alert.service';
// import { MsalService } from '@azure/msal-angular';
import { AzureAuthService } from '../../common/azure-auth.service';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators'

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    private token!: string;
    private readonly _destroying$ = new Subject<void>();
    private azure_token: string = '';

    constructor(private appToken: AppTokenHandler,
        private gservice: DataService,
        private route: Router,
        private _azureAuthService: AzureAuthService,
        // private msalService: MsalService,
        private alertService: AlertService) { }
    ngOnInit() {
        if (this.appToken.getSessionToken().length <= 0) {
            this.acquireAzureToken();
        } else {
            this.route.navigate(['/home']);
        }

        // this.acquireAzureToken();
    }

    async acquireAzureToken() {
        this._azureAuthService.handleQueryParameters()
            .pipe(
                takeUntil(this._destroying$),
                catchError(error => {
                    return throwError(() => error);
                })
            )
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.azure_token = result;
                        this.appToken.setAzureTokenInCookie(result);
                        console.log('Azure token acquired: ', this.azure_token);
                        this.GetUserDetails(result);
                    }
                }
            });
    }
    GetUserDetails(token) {
        if (this.appToken.getSessionToken().length <= 0) {
            this.gservice.LoginWithToken(token).subscribe((data: any) => {
                this.appToken.setSessionToken(data);
                this.route.navigate(['/home']);
            },
                (error: Response) => {
                    if (error.status <= 0) {
                        this.route.navigate(['/badrequest']);
                    }
                    else if (error.status == 401) {
                        this.route.navigate(['/unauthorize']);
                    }
                    else {
                        this.alertService.notify('error', 'Server Error', error.statusText);
                    }
                });
        }
        else {
            this.route.navigate(['/home']);
        }
    };
}
