import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataService } from '../../common/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppTokenHandler } from '../../common/app-token-handler';
import {
    IStatus, ICourierDetailsReport, IViewRequestDetailsFilters,
    ICourierCompany, ILocation, ILocationSubLocation, ILocationOwner,
    IEmpDetails, IViewRequestDetailsParameters
} from '../../model/courier-request-detail';

enum Roles {
    Admin = 1,
    SecurityTeamMember = 2,
    LocationOwner = 3,
    User = 4
}
@Component({
    selector: 'app-request-details-list',
    templateUrl: './request-details-list.component.html',
    styleUrls: ['./request-details-list.component.css']
})
export class RequestDetailsListComponent implements OnInit {
    CanCreateNewRequest: boolean = false;
    IsArchiveIncluded: boolean = false;
    first: number = 0;
    maxDateValue: Date = new Date();
    PageHeading: string = "View Courier Request(s)";
    RequestType: number = 1;
    CurrentDate = new Date();
    loading: boolean = false;
    IsFilterTabSelected: boolean = false;
    RequestNumber: string = "";
    AWBNumber: string = "";
    SubAWBNumber: string = "";
    SenderName: string = "";
    EmpOffPhone: string = "";
    ReceivedFromDate: any = null;
    ReceivedToDate: any = null;
    HandedOverFromDate: any = null;
    HandedOverToDate: any = null;
    DeliveredFromDate: any = null;
    DeliveredToDate: any = null;
    ViewRequestDetailsFilters: IViewRequestDetailsFilters = null;
    StatusList: Array<IStatus> = [];
    CourierCompanyList: Array<ICourierCompany> = [];
    LocationList: Array<ILocation> = [];
    SubLocationList: Array<ILocationSubLocation> = [];
    LocationOwnerList: Array<ILocationOwner> = [];
    EmpDetails: Array<IEmpDetails> = [];
    SelectedStatusList: Array<IStatus> = [];
    SelectedCourierCompanyList: Array<ICourierCompany> = [];
    SelectedLocationList: Array<ILocation> = [];
    SelectedSubLocationList: Array<ILocationSubLocation> = [];
    SelectedLocationOwnerList: Array<ILocationOwner> = [];
    SelectedRequestFor: IEmpDetails = null;
    ReportParameters: IViewRequestDetailsParameters = null;
    cols: any[];
    RequestDetailsList: Array<ICourierDetailsReport> = [];;
    SelectedRequestDetailsList: Array<ICourierDetailsReport> = [];
    constructor(private gservice: DataService, private router: Router,
        private route: ActivatedRoute, private appToken: AppTokenHandler, private cp: DatePipe) { }
    ngOnInit() {
        this.CheckIfNewRequestLinkAvailable();
        let requestType: any = this.route.snapshot.paramMap.get('type');
        if (requestType) {
            this.RequestType = <number>requestType;
            if (this.RequestType == 2) {
                this.PageHeading = "Pending for Update";
            }
        }
        let ID: string = this.route.snapshot.paramMap.get('id');
        if (ID) {
            this.RequestNumber = this.gservice.DecryptData(ID);
        }
        this.cols = [
            { field: 'RequestNumber', header: 'Request Number' },
            { field: 'ReceivedDate', header: 'Received Date', hidden: true },
            { field: 'PacketReceivedType', header: 'Packet Received', hidden: true },
            { field: 'PacketType', header: 'Packet Type', hidden: true },
            { field: 'AWBNumber', header: 'AWB Number' },
            { field: 'SubAWBNumber', header: 'Sub AWB Number' },
            { field: 'ReceivedFromOfficeName', header: 'Received From Office', hidden: true },
            { field: 'LocationName', header: 'Received Location' },
            { field: 'SubLocationName', header: 'Received Sub Location', hidden: true },
            { field: 'LocationOwnerName', header: 'Location Person', hidden: true },
            { field: 'CourierCompanyName', header: 'Courier Name' },
            { field: 'OtherCourierCompanyName', header: 'Other Courier Name', hidden: true },
            { field: 'SenderName', header: 'Sender' },
            { field: 'EmpName', header: 'Received For' },
            { field: 'EmpFirm', header: 'Firm Name', hidden: true },
            { field: 'EmpBUDescr', header: 'Market Segment', hidden: true },
            { field: 'EmpOUDescr', header: 'Service Line', hidden: true },
            { field: 'EmpMobile', header: 'Mobile Number', hidden: true },
            { field: 'EmpOffDescription', header: 'Location', hidden: true },
            { field: 'FloorNumber', header: 'Floor', hidden: true },
            { field: 'AdditionalComments', header: 'Comments', hidden: true },
            { field: 'HandedOverDate', header: 'Handed Over Date', hidden: true },
            { field: 'HandedOverToName', header: 'Handed Over To', hidden: true },
            { field: 'EmpOffPhone', header: 'Phone' },
            { field: 'StatusDesc', header: 'Status' },
            { field: 'CreatedOn', header: 'Created On', hidden: true },
            { field: 'CreatedByName', header: 'Created By', hidden: true }
        ];
        this.InitializeFilters();
    }
    InitializeFilters() {
        this.gservice.GetData("GetViewRequestDetailsFilters").subscribe((data: any) => {
            this.ViewRequestDetailsFilters = data;
            this.StatusList = this.ViewRequestDetailsFilters.StatusList;
            this.CourierCompanyList = this.ViewRequestDetailsFilters.CourierCompanyList;
            this.LocationList = this.ViewRequestDetailsFilters.LocationList;
            this.SubLocationList = this.ViewRequestDetailsFilters.SubLocationList;
            this.LocationOwnerList = this.ViewRequestDetailsFilters.LocationOwnerList;
            this.GetResult();
        });
    }
    RefreshSearchResult() {
        this.first = 0;
        this.loading = false;
        this.IsArchiveIncluded = false;
        this.IsFilterTabSelected = false;
        this.RequestNumber = "";
        this.AWBNumber = "";
        this.SubAWBNumber = "";
        this.SenderName = "";
        this.EmpOffPhone = "";
        this.ReceivedFromDate = null;
        this.ReceivedToDate = null;
        this.HandedOverFromDate = null;
        this.HandedOverToDate = null;
        this.DeliveredFromDate = null;
        this.DeliveredToDate = null;
        this.ViewRequestDetailsFilters = null;
        this.StatusList = [];
        this.CourierCompanyList = [];
        this.LocationList = [];
        this.SubLocationList = [];
        this.LocationOwnerList = [];
        this.EmpDetails = [];
        this.SelectedStatusList = [];
        this.SelectedCourierCompanyList = [];
        this.SelectedLocationList = [];
        this.SelectedSubLocationList = [];
        this.SelectedLocationOwnerList = [];
        this.SelectedRequestFor = null;
        this.ReportParameters = null;
        this.RequestDetailsList = [];
        this.SelectedRequestDetailsList = [];
        this.InitializeFilters();
    }
    onLocationChange() {
        if (this.SelectedLocationList != undefined || this.SelectedLocationList != null) {
            this.SubLocationList = null;
            this.SelectedSubLocationList = null;
            this.LocationOwnerList = null;
            this.SelectedLocationOwnerList = null;
            this.gservice.PostData("GetSubLocations", this.SelectedLocationList).subscribe((data: any) => {
                this.SubLocationList = data;
            });
        }
    }
    onSubLocationChange() {
        if ((this.SelectedLocationList != undefined || this.SelectedLocationList != null) && (this.SelectedSubLocationList != undefined || this.SelectedSubLocationList != null)) {
            this.LocationOwnerList = null;
            this.SelectedLocationOwnerList = null;
            this.gservice.PostData("GetLocationOwnersWithInactive", this.SelectedSubLocationList).subscribe((data: any) => {
                this.LocationOwnerList = data;
            });
        }
    }
    filterEmployeeSingle(event: any) {
        this.SelectedRequestFor = null;
        const query = event.query;
        if (query) {
            if (query.length >= 3 && this.gservice.ContainsSpecialCharacters(query) == false) {
                this.gservice.GetEmployeeByName('GetEmployeeDetailsByName/', query)
                    .subscribe((data: any) => {
                        this.EmpDetails = [];
                        if (data.length > 0) {
                            this.EmpDetails = data;
                        }
                    });
            }
        }
    }
    OnEmployeeSelect(event: any) {
        this.SelectedRequestFor = this.EmpDetails.find(i => i.EmpGPN === event.EmpGPN);
    }
    GetResult() {
        this.first = 0;
        this.loading = true;
        this.ReportParameters = {
            RequestType: this.RequestType,
            RequestNumber: this.RequestNumber,
            AWBNumber: this.AWBNumber,
            SubAWBNumber: this.SubAWBNumber,
            SenderName: this.SenderName,
            EmpOffPhone: this.EmpOffPhone,
            SelectedStatusList: this.SelectedStatusList,
            SelectedCourierCompanyList: this.SelectedCourierCompanyList,
            SelectedLocationList: this.SelectedLocationList,
            SelectedSubLocationList: this.SelectedSubLocationList,
            SelectedLocationOwnerList: this.SelectedLocationOwnerList,
            SelectedRequestFor: this.SelectedRequestFor,
            ReceivedFromDate: this.ReceivedFromDate == null ? this.ReceivedFromDate : this.ReceivedFromDate.toDateString(),
            ReceivedToDate: this.ReceivedToDate == null ? this.ReceivedToDate : this.ReceivedToDate.toDateString(),
            HandedOverFromDate: this.HandedOverFromDate == null ? this.HandedOverFromDate : this.HandedOverFromDate.toDateString(),
            HandedOverToDate: this.HandedOverToDate == null ? this.HandedOverToDate : this.HandedOverToDate.toDateString(),
            DeliveredFromDate: this.DeliveredFromDate == null ? this.DeliveredFromDate : this.DeliveredFromDate.toDateString(),
            DeliveredToDate: this.DeliveredToDate == null ? this.DeliveredToDate : this.DeliveredToDate.toDateString(),
            IsArchiveIncluded: this.IsArchiveIncluded
        }
        this.gservice.PostData("RequestDetailsViewReport", this.ReportParameters).subscribe((data: any) => {
            this.RequestDetailsList = data;
            this.loading = false;
            this.IsFilterTabSelected = false;
        });
    }
    onCickRequestNumber(RequestNumber) {
        this.RedirectToCreateNewRequestPage(RequestNumber);
    }
    OnOpenCloseAccordingControl(status: boolean) {
        this.IsFilterTabSelected = status;
    }
    IsContactPersonFieldVisible() {
        let visible: boolean = false;
        if (this.appToken.IsInRole(Roles[Roles.Admin]) || this.appToken.IsInRole(Roles[Roles.LocationOwner])) {
            visible = true;
        }
        return visible;
    }
    paginate(event) {
        this.first = event.first;
    }
    CheckIfNewRequestLinkAvailable() {
        this.CanCreateNewRequest = false;
        if (this.appToken.IsInRole(Roles[Roles.Admin]) || this.appToken.IsInRole(Roles[Roles.SecurityTeamMember]) || this.appToken.IsInRole(Roles[Roles.LocationOwner])) {
            this.CanCreateNewRequest = true;
        }
    }
    RedirectToCreateNewRequestPage(RequestNumber?: string) {
        this.appToken.setRequestType(this.RequestType.toString());
        if (RequestNumber != null) {
            this.router.navigate(['home/new', this.gservice.EncryptData(RequestNumber)]);
        }
        else {
            this.router.navigate(['home/new']);
        }
    }
}