import { Component, OnInit } from '@angular/core';
import { DataService } from '../../common/data.service';
import { AppTokenHandler } from '../../common/app-token-handler';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IViewRequestDetailsFilters, ILocation, ILocationSubLocation, ILocationOwner, ILocationOwnerReportParameters, IMappedLocationOwnerList, IEmpDetails, ILocationOwnerIUDParameters, IAuditTrail } from '../../model/courier-request-detail';

export enum Flag {
    Insert = 1,
    Update = 2,
    InActive = 3,
    Active = 4
}
enum Roles {
    Admin = 1,
    SecurityTeamMember = 2,
    LocationOwner = 3,
    User = 4
}
@Component({
    selector: 'app-location-owner-mapping',
    templateUrl: './location-owner-mapping.component.html',
    styleUrls: ['./location-owner-mapping.component.css']
})
export class LocationOwnerMappingComponent implements OnInit {
    first: number = 0;
    action: string = "";
    actionType: string = "";
    loading: boolean = false;
    IsFilterTabSelected: boolean = false;
    displayEditForm: boolean = false;
    ViewRequestDetailsFilters: IViewRequestDetailsFilters = null;
    LocationList: Array<ILocation> = [];
    SubLocationList: Array<ILocationSubLocation> = [];
    SelectedLocationList: Array<ILocation> = [];
    SelectedSubLocationList: Array<ILocationSubLocation> = [];
    LocationOwnerList: Array<ILocationOwner> = [];
    SelectedLocationOwnerList: Array<ILocationOwner> = [];
    cols: any[];
    LocationOwnerReportParameters: ILocationOwnerReportParameters = null;
    MappedLocationOwnerList: Array<IMappedLocationOwnerList> = [];
    SelectedMappedLocationOwnerList: Array<IMappedLocationOwnerList> = [];
    SelectedMappedStatusLocationOwnerList: Array<IMappedLocationOwnerList> = [];
    EmpDetails: Array<IEmpDetails> = [];
    SelectedEmployees: Array<IEmpDetails> = [];
    LocationOwnerIUDParameters: ILocationOwnerIUDParameters = null;
    StatusList = [{ StatusID: 1, StatusName: 'Active' }, { StatusID: 0, StatusName: 'In-Active' }]
    SelectedStatus = [];
    AuditTrail: Array<IAuditTrail> = [];
    ViewAuditTrail: boolean = false;
    auditTrailColumns: any = [];
    constructor(private gservice: DataService, private router: Router, private route: ActivatedRoute, private messageService: MessageService, private appToken: AppTokenHandler) { }
    ngOnInit() {
        if (!this.appToken.IsInRole(Roles[Roles.Admin])) {
            this.router.navigate(['/unauthorize']);
        }
        this.cols = [
            { field: 'RecordID', header: 'Record ID' },
            { field: 'LocationName', header: 'Location' },
            { field: 'SubLocationName', header: 'Sub Location' },
            { field: 'LocationOwnerName', header: 'Location Owner Name' },
            { field: 'LocationOwnerEmail', header: 'Location Owner Email' },
            { field: 'Status', header: 'Status' }
        ];
        this.auditTrailColumns = [
            { field: 'RecordID', header: 'Record ID' },
            { field: 'Action', header: 'Action' },
            { field: 'DisplayName', header: 'Field Name' },
            { field: 'OldValue', header: 'Old Value' },
            { field: 'NewValue', header: 'New Value' },
            { field: 'CreatedBy', header: 'Created By' },
            { field: 'CreatedOn', header: 'Created On' }
        ];
        this.InitializeFilters();
    }
    InitializeFilters() {
        this.gservice.GetData("GetViewRequestDetailsFilters").subscribe((data: any) => {
            this.ViewRequestDetailsFilters = data;
            this.LocationList = this.ViewRequestDetailsFilters.LocationList;
            this.SubLocationList = this.ViewRequestDetailsFilters.SubLocationList;
            this.LocationOwnerList = this.ViewRequestDetailsFilters.LocationOwnerList;
            this.GetSearchResult();
        });
    }
    RefreshSearchResult() {
        this.first = 0;
        this.action = "";
        this.actionType = "";
        this.loading = false;
        this.IsFilterTabSelected = false;
        this.displayEditForm = false;
        this.LocationList = [];
        this.SelectedLocationList = [];
        this.SubLocationList = [];
        this.SelectedSubLocationList = [];
        this.LocationOwnerList = [];
        this.SelectedLocationOwnerList = [];
        this.MappedLocationOwnerList = [];
        this.SelectedMappedLocationOwnerList = [];
        this.SelectedMappedStatusLocationOwnerList = [];
        this.EmpDetails = [];
        this.SelectedEmployees = [];
        this.LocationOwnerReportParameters = null;
        this.LocationOwnerIUDParameters = null;
        this.SelectedStatus = [];
        this.AuditTrail = [];
        this.ViewAuditTrail = false;
        this.InitializeFilters();
    }
    GetSubLocations(_LocationList: Array<ILocation>) {
        return this.gservice.PostData("GetSubLocations", _LocationList);
    }
    GetLocationOwners(_LocationSubLocation: Array<ILocationSubLocation>) {
        return this.gservice.PostData("GetLocationOwnersWithInactive", _LocationSubLocation);
    }
    onLocationChange() {
        if (this.SelectedLocationList != undefined && this.SelectedLocationList != null) {
            this.SubLocationList = [];
            this.SelectedSubLocationList = [];
            this.GetSubLocations(this.SelectedLocationList).subscribe((data: any) => {
                this.SubLocationList = data;
            });
        }
    }
    onSubLocationChange() {
        if (this.SelectedSubLocationList != undefined && this.SelectedSubLocationList != null) {
            this.LocationOwnerList = [];
            this.SelectedLocationOwnerList = [];
            this.GetLocationOwners(this.SelectedSubLocationList).subscribe((data: any) => {
                this.LocationOwnerList = data;
            });
        }
    }
    GetSearchResult() {
        this.first = 0;
        this.loading = true;
        let StatusList: string = "";
        if (this.SelectedStatus.length > 0) {
            StatusList = this.SelectedStatus.map(e => e.StatusID).join(";");
        }
        this.LocationOwnerReportParameters = {
            LocationList: this.SelectedLocationList,
            SubLocationsList: this.SelectedSubLocationList,
            LocationOwnerList: this.SelectedLocationOwnerList,
            StatusList: StatusList
        }
        this.gservice.PostData("GetLocationOwnerReport", this.LocationOwnerReportParameters).subscribe((data: any) => {
            this.MappedLocationOwnerList = data;
            this.loading = false;
        });
        this.IsFilterTabSelected = false;
    }
    //filterEmployeeSingle(event: any, searchType: number = 0) {
    //    const query = event.query;
    //    if (query) {
    //        if (query.length >= 3 && this.gservice.ContainsSpecialCharacters(query) == false) {
    //            this.gservice.GetEmployeeByName('GetEmployeeDetailsByName/', query)
    //                .subscribe((data: any) => {
    //                    this.EmpDetails = [];
    //                    if (data.length > 0) {
    //                        this.EmpDetails = data;
    //                    }
    //                });
    //        }
    //    }
    //}
    filterEmployeeSingle(event: any) {       
        const query = event.query;
        if (query) {
            if (query.length >= 3) {
                let locationList = '';
                let sublocationlist = '';
                if (this.SelectedLocationList.length > 0) {
                    locationList = this.SelectedLocationList.map(x => x.LocationID).join(';');
                }
                if (this.SelectedSubLocationList.length > 0) {
                    sublocationlist = this.SelectedSubLocationList.map(x => x.SubLocationID).join(';');
                }
                let EmpSearchParameter = {
                    SearchType: 0, EmpGPN: '',
                    EmpName: query,
                    LocationID: locationList,
                    SubLocationID: sublocationlist
                };
                this.gservice.PostData('GetEmployeeDetailsByName/', EmpSearchParameter)
                    .subscribe((data: any) => {
                        this.EmpDetails = [];
                        if (data.length > 0) {
                            this.EmpDetails = data;
                        }
                    });
            }
        }
    }
    AddLocationOwner() {
        this.displayEditForm = true;
    }
    MakeDBOperation(_parms: ILocationOwnerIUDParameters) {
        return this.gservice.PostData("SetLocationOwners", _parms);
    }
    SubmitLocationOwners() {
        this.LocationOwnerIUDParameters = {
            Flag: Flag.Insert,
            MappedLocationOwnerList: this.SelectedMappedLocationOwnerList,
            LocationSubLocation: this.SelectedSubLocationList,
            EmpDetailsList: this.SelectedEmployees
        };
        this.MakeDBOperation(this.LocationOwnerIUDParameters).subscribe((data: any) => {
            this.GetLocationOwners(this.SelectedSubLocationList).subscribe((data: any) => {
                let employees: Array<ILocationOwner> = [];
                this.SelectedEmployees.map(x => employees.splice(0, 0, { LocationOwnerGPN: x.EmpGPN, LocationOwnerName: x.EmpNameOnly }));
                this.LocationOwnerList = data;
                this.SelectedLocationOwnerList = employees;              
                this.EmpDetails = [];
                this.SelectedEmployees = [];
                this.LocationOwnerIUDParameters = null;
                this.GetSearchResult();
            });
        })
        this.displayEditForm = false;
    }
    UpdateLocationOwners() {
        let _action: Flag;
        if (this.action == 'active') {
            _action = Flag.Active;
        }
        else if (this.action == 'inactive') {
            _action = Flag.InActive;
        }
        this.LocationOwnerIUDParameters = {
            Flag: _action,
            MappedLocationOwnerList: (this.actionType == 'bulk' ? this.SelectedMappedLocationOwnerList : this.SelectedMappedStatusLocationOwnerList),
            LocationSubLocation: this.SelectedSubLocationList,
            EmpDetailsList: this.SelectedEmployees
        };
        this.MakeDBOperation(this.LocationOwnerIUDParameters).subscribe((data: any) => {
            this.SelectedMappedLocationOwnerList = [];
            this.SelectedMappedStatusLocationOwnerList = [];
            this.GetSearchResult();
        })
        this.action = "";
        this.actionType = "";
    }
    OnOpenCloseAccordingControl(status: boolean) {
        this.IsFilterTabSelected = status;
    }
    CanSubmit(): boolean {
        let disabled: boolean = true;
        disabled = (this.SelectedSubLocationList.length > 0 && this.SelectedEmployees.length > 0) ? false : true;
        return disabled;
    }
    showConfirm(action: string, actionType: string) {
        this.action = action;
        this.actionType = actionType;
        let message: string = "";
        if (this.action == 'active'){
            message = 'Are you sure you want to activate the selected mapping?'
        }
        else if (this.action == 'inactive') {
            message = 'Are you sure you want to deactivate the selected mapping?'
        }
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: message, detail: '' });
    }
    onConfirm() {
        this.messageService.clear('c');
        this.UpdateLocationOwners();       
    }
    onReject() {
        this.SelectedMappedLocationOwnerList = [];
        this.SelectedMappedStatusLocationOwnerList = [];
        this.messageService.clear('c');
    }
    clear() {
        this.SelectedMappedLocationOwnerList = [];
        this.SelectedMappedStatusLocationOwnerList = [];
        this.messageService.clear();
    }
    onCickStatus(LocationOwnerID) {
        let selectedStatus: Array<IMappedLocationOwnerList> = [];
        let status: boolean = this.MappedLocationOwnerList.find(x => x.LocationOwnerID == LocationOwnerID).IsActive;
        this.SelectedMappedStatusLocationOwnerList = [];
        this.SelectedMappedStatusLocationOwnerList.push(this.MappedLocationOwnerList.find(x => x.LocationOwnerID == LocationOwnerID));
        this.showConfirm((status == true?'inactive':'active'),'single');
    }
    CanActivate(status: boolean) {
        let active: boolean = false;
        if (this.SelectedMappedLocationOwnerList.length > 0) {
            let result: any[] = this.SelectedMappedLocationOwnerList.filter(x => x.IsActive == status);
            if (result.length > 0) {
                active = false;
            }
            else {
                active = true;
            }
        }
        return active;
    }
    GetAuditTrail(LocationOwnerID) {
        this.AuditTrail = [];
        this.gservice.GetData("GetAuditTrail/" + LocationOwnerID+"/2").subscribe((data: any) => {
            this.AuditTrail = data;
            this.ViewAuditTrail = true;
        });
    }
    paginate(event) {
        this.first = event.first;
    }
}
