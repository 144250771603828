import { Component, OnInit } from '@angular/core';
import { Form } from '@angular/forms';
import { DataService } from '../../common/data.service';
import { AppTokenHandler } from '../../common/app-token-handler';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IViewRequestDetailsFilters, ILocation, ILocationSubLocation, IFloor, ILocationFloorReportParameters, IMappedLocationFloorList, ILocationFloorIUDParameters, IAuditTrail } from '../../model/courier-request-detail';

export enum Flag {
    Insert = 1,
    Update = 2,
    InActive = 3,
    Active = 4
}
enum Roles {
    Admin = 1,
    SecurityTeamMember = 2,
    LocationOwner = 3,
    User = 4
}
@Component({
    selector: 'app-location-floor-mapping',
    templateUrl: './location-floor-mapping.component.html',
    styleUrls: ['./location-floor-mapping.component.css']
})
export class LocationFloorMappingComponent implements OnInit {
    first: number = 0;
    action: string = "";
    actionType: string = "";
    loading: boolean = false;
    IsFilterTabSelected: boolean = false;
    displayEditForm: boolean = false;
    ViewRequestDetailsFilters: IViewRequestDetailsFilters = null;
    LocationList: Array<ILocation> = [];
    SubLocationList: Array<ILocationSubLocation> = [];
    FloorList: Array<IFloor> = [];
    SelectedLocationList: Array<ILocation> = [];
    SelectedSubLocationList: Array<ILocationSubLocation> = [];
    SelectedFloorList: Array<IFloor> = [];
    cols: any[];
    LocationFloorReportParameters: ILocationFloorReportParameters = null;
    MappedLocationFloorList: Array<IMappedLocationFloorList> = [];
    SelectedMappedLocationFloorList: Array<IMappedLocationFloorList> = [];
    SelectedMappedStatusLocationFloorList: Array<IMappedLocationFloorList> = [];
    LocationFloorIUDParameters: ILocationFloorIUDParameters = null;
    StatusList = [{ StatusID: 1, StatusName: 'Active' }, { StatusID: 0, StatusName: 'In-Active' }]
    SelectedStatus = [];
    AuditTrail: Array<IAuditTrail> = [];
    ViewAuditTrail: boolean = false;
    auditTrailColumns: any = [];
    constructor(private gservice: DataService, private router: Router, private route: ActivatedRoute, private messageService: MessageService, private appToken: AppTokenHandler) { }
    ngOnInit() {
        if (!this.appToken.IsInRole(Roles[Roles.Admin])) {
            this.router.navigate(['/unauthorize']);
        }
        this.cols = [
            { field: 'RecordID', header: 'Record ID' },
            { field: 'LocationName', header: 'Location' },
            { field: 'SubLocationName', header: 'Sub Location' },
            { field: 'FloorNumber', header: 'Floor' },
            { field: 'Status', header: 'Status' }
        ];
        this.auditTrailColumns = [
            { field: 'RecordID', header: 'Record ID' },
            { field: 'Action', header: 'Action' },
            { field: 'DisplayName', header: 'Field Name' },
            { field: 'OldValue', header: 'Old Value' },
            { field: 'NewValue', header: 'New Value' },
            { field: 'CreatedBy', header: 'Created By' },
            { field: 'CreatedOn', header: 'Created On' }
            //{ field: 'ClientDevice', header: 'Client Device' }
        ];
        this.InitializeFilters();
    }
    InitializeFilters() {
        this.gservice.GetData("GetViewRequestDetailsFilters").subscribe((data: any) => {
            this.ViewRequestDetailsFilters = data;
            this.LocationList = this.ViewRequestDetailsFilters.LocationList;
            this.SubLocationList = this.ViewRequestDetailsFilters.SubLocationList;
            this.FloorList = this.ViewRequestDetailsFilters.FloorList;
            this.GetSearchResult();
        });
    }
    RefreshSearchResult() {
        this.first = 0;
        this.action = "";
        this.actionType = "";
        this.loading = false;
        this.IsFilterTabSelected = false;
        this.displayEditForm = false;
        this.ViewRequestDetailsFilters = null;
        this.LocationList = [];
        this.SubLocationList = [];
        this.FloorList = [];
        this.SelectedLocationList = [];
        this.SelectedSubLocationList = [];
        this.SelectedFloorList = [];
        this.LocationFloorReportParameters = null;
        this.MappedLocationFloorList = [];
        this.SelectedMappedLocationFloorList = [];
        this.SelectedMappedStatusLocationFloorList = [];
        this.LocationFloorIUDParameters = null;
        this.SelectedStatus = [];
        this.AuditTrail = [];
        this.ViewAuditTrail = false;
        this.InitializeFilters();
    }
    GetSearchResult() {
        this.first = 0;
        this.loading = true;
        let StatusList: string = "";
        if (this.SelectedStatus.length > 0) {
            StatusList = this.SelectedStatus.map(e => e.StatusID).join(";");
        }
        this.LocationFloorReportParameters = { SubLocationsList: this.SelectedSubLocationList, FloorList: this.SelectedFloorList, StatusList: StatusList, LocationsList: this.SelectedLocationList };
        this.gservice.PostData("GetLocationFloorReport", this.LocationFloorReportParameters).subscribe((data: Array<IMappedLocationFloorList>) => {
            this.MappedLocationFloorList = data;
            this.loading = false;
        });
        this.IsFilterTabSelected = false;
    }
    GetSubLocations(_LocationList: Array<ILocation>) {
        return this.gservice.PostData("GetSubLocations", _LocationList);
    }
    onLocationChange() {
        if (this.SelectedLocationList != undefined && this.SelectedLocationList != null) {
            this.SubLocationList = [];
            this.SelectedSubLocationList = [];
            this.GetSubLocations(this.SelectedLocationList).subscribe((data: any) => {
                this.SubLocationList = data;
            });
        }
    }
    MakeDBOperation(_parms: ILocationFloorIUDParameters) {
        return this.gservice.PostData("SetLocationFloors", _parms);
    }
    AddLocationOwner() {
        this.displayEditForm = true;
    }
    SubmitLocationFloors() {
        this.LocationFloorIUDParameters = {
            Flag: Flag.Insert,
            MappedLocationFloorList: this.SelectedMappedLocationFloorList,
            SelectedSubLocationList: this.SelectedSubLocationList,
            SelectedFloorList: this.SelectedFloorList
        }
        this.MakeDBOperation(this.LocationFloorIUDParameters).subscribe((data: number) => {
            this.GetSearchResult();
        });
        this.displayEditForm = false;
    }
    UpdateFloorMapping() {
        let _action: Flag;
        if (this.action == 'active') {
            _action = Flag.Active;
        }
        else if (this.action == 'inactive') {
            _action = Flag.InActive;
        }
        this.LocationFloorIUDParameters = {
            Flag: _action,
            MappedLocationFloorList: (this.actionType == 'bulk' ? this.SelectedMappedLocationFloorList : this.SelectedMappedStatusLocationFloorList),
            SelectedSubLocationList: this.SelectedSubLocationList,
            SelectedFloorList: this.SelectedFloorList
        }
        this.MakeDBOperation(this.LocationFloorIUDParameters).subscribe((data: number) => {
            this.SelectedMappedLocationFloorList = [];
            this.SelectedMappedStatusLocationFloorList = [];
            this.GetSearchResult();
        });
        this.action = "";
        this.actionType = "";
    }
    OnOpenCloseAccordingControl(status: boolean) {
        this.IsFilterTabSelected = status;
    }
    CanSubmit(): boolean {
        let disabled: boolean = true;
        disabled = (this.SelectedSubLocationList.length > 0 && this.SelectedFloorList.length > 0) ? false : true;
        return disabled;
    }
    showConfirm(action: string, actionType: string) {
        this.action = action;
        this.actionType = actionType;
        let message: string = "";
        if (this.action == 'active') {
            message = 'Are you sure you want to activate the selected mapping?'
        }
        else if (this.action == 'inactive') {
            message = 'Are you sure you want to deactivate the selected mapping?'
        }
        this.messageService.clear();
        this.messageService.add({ key: 'c', sticky: true, severity: 'warn', summary: message, detail: '' });
    }
    onConfirm() {
        this.messageService.clear('c');
        this.UpdateFloorMapping();
    }
    onReject() {
        this.SelectedMappedLocationFloorList = [];
        this.SelectedMappedStatusLocationFloorList = [];
        this.messageService.clear('c');
    }
    clear() {
        this.SelectedMappedLocationFloorList = [];
        this.SelectedMappedStatusLocationFloorList = [];
        this.messageService.clear();
    }
    onCickStatus(LocationFloorID) {
        let selectedStatus: Array<IMappedLocationFloorList> = [];
        let status: boolean = this.MappedLocationFloorList.find(x => x.LocationFloorID == LocationFloorID).IsActive;
        this.SelectedMappedStatusLocationFloorList = [];
        this.SelectedMappedStatusLocationFloorList.push(this.MappedLocationFloorList.find(x => x.LocationFloorID == LocationFloorID));
        this.showConfirm((status == true ? 'inactive' : 'active'), 'single');
    }
    CanActivate(status: boolean) {
        let active: boolean = false;
        if (this.SelectedMappedLocationFloorList.length > 0) {
            let result: any[] = this.SelectedMappedLocationFloorList.filter(x => x.IsActive == status);
            if (result.length > 0) {
                active = false;
            }
            else {
                active = true;
            }
        }
        return active;
    }
    GetAuditTrail(LocationFloorID) {
        this.AuditTrail = [];
        this.gservice.GetData("GetAuditTrail/" + LocationFloorID + "/3").subscribe((data: any) => {
            this.AuditTrail = data;
            this.ViewAuditTrail = true;
        });
    }
    paginate(event) {
        this.first = event.first;
    }
}
