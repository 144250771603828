import { APP_BASE_HREF, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenubarModule } from 'primeng/menubar';
import { TooltipModule } from 'primeng/tooltip';
import { KeyFilterModule } from 'primeng/keyfilter';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { AppComponent } from './app.component';
import { DataService, UIBlockingStatus } from './common/data.service';
import { AuthGuardService } from './common/auth-guard.service';
import { AppTokenHandler } from './common/app-token-handler';
import { ErrorHandler } from '@angular/core';
import { AppErrorHandler } from './common/app-error-handler';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { WinAuthInterceptor } from './common/interceptor';
import { HomeComponent } from '../app/inside-of-application/home/home.component';
import { DashboardComponent } from './inside-of-application/dashboard/dashboard.component';
import { BadRequestComponent } from '../app/out-of-application/bad-request/bad-request.component';
import { PageNotFoundComponent } from '../app/out-of-application/page-not-found/page-not-found.component';
import { LogoutComponent } from '../app/out-of-application/logout/logout.component';
import { UnAuthorizeComponent } from '../app/out-of-application/un-authorize/un-authorize.component';
import { SessionExpiredComponent } from './out-of-application/session-expired/session-expired.component';
import { LoginComponent } from './out-of-application/login/login.component';
import { DummyLoginComponent } from './out-of-application/dummy-login/dummy-login.component';
import { AlertComponent } from './out-of-application/alert/alert.component';
import { ProgressComponent } from './out-of-application/progress/progress.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { OrderListModule } from 'primeng/orderlist';
import { DataViewModule } from 'primeng/dataview';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CreateNewRequestComponent } from './inside-of-application/create-new-request/create-new-request.component';
import { RequestDetailsListComponent } from './inside-of-application/request-details-list/request-details-list.component';
import { LocationOwnerMappingComponent } from './inside-of-application/location-owner-mapping/location-owner-mapping.component';
import { LocationFloorMappingComponent } from './inside-of-application/location-floor-mapping/location-floor-mapping.component';
import { ViewRequestComponent } from './out-of-application/view-request/view-request.component';
import { ScrollingModule } from '@angular/cdk/scrolling';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

@NgModule({
    declarations: [
        AppComponent, HomeComponent, DashboardComponent,
        BadRequestComponent, PageNotFoundComponent, LogoutComponent, UnAuthorizeComponent,
        SessionExpiredComponent, LoginComponent, DummyLoginComponent, AlertComponent, ProgressComponent,
        CreateNewRequestComponent, RequestDetailsListComponent, LocationOwnerMappingComponent, LocationFloorMappingComponent, ViewRequestComponent
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule, ReactiveFormsModule, AppRoutingModule, HttpClientModule,
        ToastModule, MessageModule, DropdownModule, CheckboxModule, MultiSelectModule, MenubarModule, TooltipModule,
        KeyFilterModule, DialogModule, TableModule, CardModule, CalendarModule,
        CarouselModule, BlockUIModule, ProgressSpinnerModule, RadioButtonModule, FormsModule,
        AccordionModule, OrderListModule, DataViewModule, ButtonModule, InputTextareaModule,
        AutoCompleteModule, HttpClientJsonpModule,ScrollingModule,
    ],
    providers: [
        DataService, AppTokenHandler, AuthGuardService, UIBlockingStatus, MessageService, 
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WinAuthInterceptor,
            multi: true,
            // useValue: '/'
        },
        // { provide: ErrorHandler, useClass: AppErrorHandler },
        // { provide: APP_BASE_HREF, useValue: '/' },
        // // { provide: LocationStrategy, useClass: HashLocationStrategy },

        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
