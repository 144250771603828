import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../common/data.service';
import { AppTokenHandler } from '../../common/app-token-handler';
import { AlertService } from '../../common/alert.service';

@Component({
    selector: 'app-view-request',
    templateUrl: './view-request.component.html',
    styleUrls: ['./view-request.component.css']
})
export class ViewRequestComponent implements OnInit {
    RequestNumber: string = "";
    constructor(private appToken: AppTokenHandler, private gservice: DataService, private router: Router,
        private route: ActivatedRoute, private alertService: AlertService) {
    }

    ngOnInit() {
        let ID: string = this.route.snapshot.paramMap.get('id');
        if (ID) {
            this.RequestNumber = this.gservice.EncryptData(ID);
            if (this.RequestNumber.length > 0) {
                this.GetUserDetails();
            }
        }
        else {
            this.router.navigate(['/unauthorize']);
        }
    }
    GetUserDetails() {
        this.gservice.Login().subscribe((data: any) => {
            this.appToken.setSessionToken(data);
            this.router.navigate(['/home/list', this.RequestNumber]);
        },
            (error: Response) => {
                if (error.status <= 0) {
                    this.router.navigate(['/badrequest']);
                }
                else if (error.status == 401) {
                    this.router.navigate(['/unauthorize']);
                }
                else {
                    this.alertService.notify('error', 'Server Error', error.statusText);
                }
            });
    };
}
