import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressService } from '../../common/progress.service';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./progress.component.css']
})
export class ProgressComponent implements OnInit, OnDestroy {
    IsUIBlocked: boolean = false;
    subscription: Subscription;
    constructor(private progressService: ProgressService) { }
    ngOnInit() {
        this.subscribeToNotifications();
    }

    subscribeToNotifications() {
        this.subscription = this.progressService.notificationChange
            .subscribe((status: boolean) => {
                this.IsUIBlocked = status;
           });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
