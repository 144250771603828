import { UntypedFormControl, ValidationErrors, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { DataService } from '../../common/data.service';

export class CustomValidators {
    static PhoneFormat(control: UntypedFormControl): ValidationErrors | null {
        const _value = control.value;
        const _pattern = /^(([+0][0-9 /S-]{1,16}))*$/;
        if (!_pattern.test(_value)) {
            return {
                PhoneFormat: 'Should be in Format +91 120 675 7396 or +91-120-675-7396'
            };
        }
        return null;
    }
    static SpecialCharacterCheck(control: UntypedFormControl): ValidationErrors | null {
        const _value = control.value;
        const _pattern = /[!@#$%^*()?':{}|<>_]/;
        if (_pattern.test(_value)) {
            return {
                SpecialCharacterCheck: "* Special character [!@#$%^*()?':{ }| <>_] are not allowed"
            };
        }
        return null;
    }
    static IsUniqueSubAWBNumber(dataService: DataService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            return Observable.create(observer => {
                let RequestID: string = control.parent.controls["RequestID"].value;
                let AWBNumber: string = control.parent.controls["AWBNumber"].value;
                let SubAWBNumber: string = control.parent.controls["SubAWBNumber"].value;
                let _url: string = "IsUniqueSubAWBNumber/" + RequestID + "/" + SubAWBNumber + (AWBNumber.length <= 0 ? "" : ("/" + AWBNumber));

                // setTimeout(() => {
                    dataService.GetDataDebounce(_url).subscribe(
                        (data: any) => {
                            let result: any;
                            if (data == true) {
                                result = { 'IsUniqueSubAWBNumber': '* Combination of AWB Number and Sub AWB Number already exists in the system.' };
                            } else {
                                result = undefined;
                            }
                            observer.next(result);
                            observer.complete();
                        });
                // }, 5000);

            });
        };
    }
}
